import axios from "axios";
import { mapGetters } from "vuex";

import mixPanelUserLogin from "./MixPanelUserLogin";

export default {
  mixins: [mixPanelUserLogin],
  computed: {
    ...mapGetters({
        preferredLanguage: "preferredLanguage",
    }),
  },
  methods: {
    async login(goTo, redirect = true) {
      let response
      try {
          response = await axios.post("/login", {
              email: this.email,
              password: this.password
          });
      } catch (error) {
          return error.response.status
      }

      const user = response.data
      if (user.cart) this.$store.commit('setCart', user.cart)
      if (user) {
          this.mixPanelUserLogin(user)

          // guardar usuari logat i token a vuex
          this.$store.commit("setUser", user);

          const stripeAccountId = user.stripeAccountId;
          if (stripeAccountId) {
            await this.$store.dispatch("getExternalAccount", {
              stripeAccountId,
            });
          }

        //   let flag = user.featureFlags.find(flag => flag.code === "contributions");
        //   this.$store.commit("setContributionsFlag", flag.isEnabled);

          this.$forceRefreshState();

          this.$i18n.locale = this.preferredLanguage;

          if (redirect) {
              await this.redirect(goTo);
          } else {
              return user
          }
      }
      return response
    },
    async redirect(goTo) {
          if (goTo === "catalogue") {
              await this.$router.replace(
                  this.$route.params.wantedRoute || {
                      name: "catalogue"
                  }
              );
          } else if (goTo === "myList") {
              // eslint-disable-next-line no-undef
              await this.$router.push({
                  name: "myList",
                  params: {
                      // eslint-disable-next-line no-undef
                      name: this.$formatUrlhelloBB(response.title),
                      // eslint-disable-next-line no-undef
                      id: response.id
                  }
              });
          } else if (goTo !== '') {
              if (goTo !== "enquesta") {
                  await this.$router.replace(goTo);
              } else {
                  window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLSd-rv4-oIpkSTDpy6sj7-pSkkHUM_iHcS65y1ZSOTsQQRTNWA/viewform?usp=sf_link";
              }
          } else {
              await this.$router.replace(
                  this.$route.params.wantedRoute || {
                      name: "home"
                  }
              );
          }
      },
  }
};
